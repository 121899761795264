@import '../../styles/variables.scss';

.dashboard {
	&__tabs {
		border-bottom: 1px solid #cccccc;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;

		button.ap-button-tab:first-child {
			margin-left: 32px !important;
		}
	}
}

.watchlist-nav {
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 27px;
	color: $grey8;
	padding: 10px 0;
}

.watchlists-container {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	padding: 32px;
	background-color: $white;
	box-shadow: $box-shadow1;
	position: relative;
	width: 100%;

	padding-top: 82px;
	min-height: calc(100vh - 400px);

	&__tabs {
	}

	&__clear {
		display: block;
		min-width: 60px !important;
	}

	&__company-search {
		height: 48px;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey6;

		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 40px;
		background-color: $white;
		border-bottom: 1px solid #cccccc;
		height: 64px;
		justify-content: center;

		input {
			min-width: 550px;
		}
	}

	&__actions {
		color: $grey5;
		display: flex;
		justify-content: center;
		font-size: 8px !important;
		padding: 0 !important;
		height: 40px;
		display: flex;
		align-items: center;

		div {
			height: 20px;
			display: flex;
			align-items: center;
		}
	}

	.cell-link {
		&:hover {
			cursor: pointer;
			color: $green2;
		}

		&:active {
			color: $green3;
		}
	}

	.cell-highlight {
		font-weight: 500 !important;
		background-color: $grey3 !important;
	}

	.cell-action-wrapper {
		&--empty {
			cursor: auto;
		}

		cursor: pointer;
		height: 41px;

		display: flex;
		justify-content: center;

		&:hover {
			svg {
				fill: #333333;
			}
		}
	}

	&__list {
		padding-bottom: 40px;

		.ap-simple-table-container {
			height: 100%;
		}

		.ap-simple-table-body-cell {
			padding: 0;
			// cursor: pointer;
			border: 0;
		}

		.ap-simple-table-row {
			&:hover {
				> td {
					background-color: $grey1;
				}

				.cell-link {
					text-decoration: underline;
				}
			}
		}

		.cell-wrapper {
			min-height: 41px;
			padding: 12px;
		}

		.cell-watchlist {
			height: 40px;
			display: flex;
			align-items: center;

			&:last-child {
				border-bottom: 1px solid $grey3;
			}
		}

		.cell-company {
			padding: 12px;
			border-bottom: 1px solid $grey3;
		}

		.cell-company-data {
			min-height: 40px;
			padding: 12px;

			&:last-child {
				border-bottom: 1px solid $grey3;
			}
		}
	}

	&__h1 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0.3px;
		color: $grey8;
	}

	&__heading-wrapper {
		display: flex;
		align-items: center;
		padding-bottom: 24px;
		justify-content: space-between;
		// min-height: 60px;
	}

	&__search-wrapper {
		div:last-child {
			padding-top: 8px;
			width: 500px;
		}
	}

	&__filter {
		display: flex;
		align-items: baseline;

		button {
			margin-left: 8px;
			min-width: 150px;
		}

		button > span {
			font-family: 'Roboto' !important;
		}
	}

	.all-companies,
	.my-watchlist,
	.other-watchlist {
		.ag-root-wrapper {
			min-height: 250px;

			.ag-root-wrapper-body.ag-layout-normal {
				height: 100%;
				min-height: 100%;
			}
		}
	}
}

// .card {
// 	background-color: $white;
// 	box-shadow: $box-shadow1;
// 	padding: 24px;
// 	position: relative;
// 	width: 100%;

// 	&__title-wrapper {
// 		display: flex;
// 		width: 100%;
// 		justify-content: space-between;
// 		padding: 0 0 16px 0;
// 	}

// 	&__title {
// 		color: $grey5;
// 		font-size: 13px;
// 		font-weight: bold;
// 		letter-spacing: 0.8px;
// 		line-height: 19px;
// 		text-transform: uppercase;
// 		padding-bottom: $space-md;
// 	}

// 	&__info {
// 		color: $grey5;
// 		font-size: 13px;
// 		letter-spacing: 0;
// 		line-height: 16px;
// 		margin-bottom: 28px;
// 	}

// 	&__hr {
// 		background-color: $grey2;
// 		width: 100%;
// 		display: block;
// 		padding: 0;
// 		margin: 0 0 $space-md;
// 		height: 1px;
// 	}

// 	&__data {
// 		margin: 32px 0 0;
// 	}

// 	.watchlists {
// 		width: 100%;
// 		padding-bottom: 32px;

// 		&__no-data {
// 			color: $grey5;
// 			// font-size: 13px;
// 			letter-spacing: 0;
// 			line-height: 16px;
// 			margin-bottom: 28px;
// 		}

// 		&__header {
// 			display: flex;
// 			padding: 0 16px;
// 		}

// 		&__label {
// 			display: flex;
// 			flex: 1;
// 			color: $grey5;
// 			font-size: 11px;
// 			line-height: 13px;
// 			text-transform: uppercase;
// 			padding-bottom: 8px;
// 			letter-spacing: 0.8px;
// 		}

// 		&__name {
// 			flex: 3;
// 		}
// 	}
// }

.modal {
	&__label {
		display: flex;
		flex: 1;
		color: $grey5;
		// font-size: 11px;
		// line-height: 13px;
		// text-transform: uppercase;
		padding-bottom: 8px;
		// letter-spacing: 0.8px;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		justify-content: space-between;
	}

	&__content {
		p {
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.3px;
			color: $grey8;
			padding-bottom: 32px;
		}
	}

	&__description {
		border: 1px solid $grey4;
		border-radius: 1px;
		outline: none;
		position: relative;
		width: 100%;
		padding: 8px;
		resize: none;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #1a1a1a;

		&:hover,
		&:focus {
			border-color: $green2;
			outline: none;
		}

		&:active {
			border-color: $green3;
		}
	}

	&__textarea {
		font-family: 'Roboto';
		color: $grey8;
		font-weight: 400;
		letter-spacing: normal;
		text-align: left;
		border: 1px solid $grey2;
		border-radius: 2px;
		box-sizing: border-box;
		caret-color: $grey3;
		display: inline-flex;
		justify-content: center;
		outline: none;
		padding: 0px 8px;
		position: relative;
		width: 100%;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		padding: 8px;
		resize: none;

		&:focus {
			box-shadow: 0 0 0 1px $white, 0 0 0 2px $grey3;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.modal-add {
	.ap-modal-body {
		overflow-y: visible;
	}
}

.watchlist-header {
	display: flex;
}

.manage-columns {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	&__item {
		display: flex;
		flex-direction: column;

		&--title {
			margin-bottom: 10px;
			font-size: 12px;
		}
	}
}
