@import '../../../styles/variables.scss';

.debt-secondary-widget {
	// min-width: 260px;
	// height: 160px;
	background: $white;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	padding: 16px 20px;

	margin-right: 32px;

	display: flex;
	flex: 1;
	flex-direction: column;

	&:last-child {
		margin-right: 0;
	}

	&__title {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;

		letter-spacing: 0.8px;
		text-transform: uppercase;

		color: $grey8;

		// padding-bottom: 24px;
		padding-bottom: 16px;
	}

	&__data {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		line-height: 40px;

		letter-spacing: 0.3px;

		color: $grey8;

		margin-bottom: 16px;
	}

	&__separator {
		height: 1px;
		width: 100%;
		background-color: $grey3;
		margin-bottom: 16px;
	}

	&__change {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;

		color: $grey8;

		display: flex;
		align-items: center;
		transform: translateX(-8px);
	}

	&__change-icon {
		transform: rotate(270deg);

		&--negative {
			transform: rotate(90deg);
			fill: $red1;
		}
	}
}
