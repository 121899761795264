@import '../../styles/variables.scss';

.access-page {
	overflow: auto;
	height: 100vh;
	display: flex;
	justify-content: center;
	background-color: $white;

	.ap-button-main {
		width: 100%;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 580px;
		margin-top: 120px;
		text-align: center;
	}

	&__title {
		color: $grey7;
		margin-bottom: 32px;
		font-size: 28px;
		font-weight: 500;
		letter-spacing: 0.3px;
		line-height: 35px;
		text-align: left;
		width: 100%;
	}

	&__title-text {
		font-size: 19px;
		margin-bottom: 16px;
		font-size: 16px;
		letter-spacing: 0.3px;
		color: $grey7;
		max-width: 500px;
	}

	&__body-text {
		font-size: 16px;
		letter-spacing: 0.3px;
		color: $grey7;
		width: 400px;
		text-align: left;

		span {
			font-weight: bold;
		}
	}

	&__body-image {
		width: 300px;

		img {
			width: 100%;
		}
	}

	&__message-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&__message-wrapper {
		text-align: left;
		border: 1px solid $grey2;
		padding: 32px;
		margin: 32px;
		width: 800px;

		h4,
		h5,
		h6 {
			margin-bottom: 16px;
		}
		p {
			margin-bottom: 32px;
		}
	}

	.access-link {
		color: $green1;
		cursor: pointer;
		font-size: 16px;
		transition: all 0.2s linear 0s;
		margin-top: 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		&:hover {
			color: $green2;

			svg {
				fill: $green2;
			}
		}
	}
}
