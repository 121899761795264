.ap-table-with-numeric-column {
	padding-top: 0 !important;
	border-top: 0 !important;

	div.numericColumn {
		position: relative;
		width: 100%;
		text-align: right;
		border-bottom: 1px solid #CCCCCC;
	}
}
