@import '../../../styles/variables.scss';

.test1 {
	// background: repeating-linear-gradient(
	// 	-55deg,
	// 	red,
	// 	red 1px,
	// 	white 0px,
	// 	white 5px
	// );
	background: repeating-linear-gradient(
  45deg,
  #f6ba52,
  #f6ba52 1px,
  white 1px,
  white 6px
);
}
