@import '../../../styles/variables.scss';

.debt-form {
	&__add-new {
		display: flex;
		justify-content: flex-end;
		margin-top: 16px;
		padding-right: 16px;
	}

	&__form {
		overflow: auto;
		// max-height: 350px;
		padding-right: 16px;
		// min-height: 350px;
		height: 325px;
	}

	&__title {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.3px;
		color: $grey8;
	}

	&__row {
		display: flex;
		margin-bottom: 24px;
		align-items: flex-end;

		&:last-child {
			margin-bottom: 0;
		}

		&__delete {
			height: 40px;
			display: flex;
			align-items: center;
		}

		&__year {
			button {
				height: 40px;
				border: 1px solid #999999;
				width: 80px;
				&:hover {
					border: 1px solid $green2;
				}
			}
			margin-right: 16px;
		}
		&__month {
			button {
				height: 40px;
				border: 1px solid #999999;
				width: 115px;

				&:hover {
					border: 1px solid $green2;
				}
			}
			margin-right: 16px;
		}

		&__value {
			flex: 1;
			margin-right: 16px;
		}
	}

	&__heading {
		font-weight: 500;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #000000;
		margin: 24px 0 16px;
	}

	&__alert {
		display: flex;
		align-items: center;
		height: 46px;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;
		color: $grey8;
		background-color: #e6e6e6;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-right: 16px;

		.ap-icon {
			margin: 0 8px;
		}
	}

	&__label {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #737373;
		margin-bottom: 4px;
	}
}
