@import '@alixpartners/ui-components/theme.scss';
// For a full list of variables available from theme.scss visit https://apuicomponentsdev.azurewebsites.net/?path=/story/theme--colors

$body-min-width: 320px;
$header-height: 64px;
$header-extra-height: 112px;
$footer-height: 247px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$space-xxs: 2px;
$space-xs: 4px;
$space-sm: 8px;
$space-md: 16px;
$space-lg: 24px;
$space-xl: 32px;
$space-xxl: 64px;

// Custom colors for landing page
$custom-blue1: #ebeff2;
$font-family: 'Roboto', sans-serif;
