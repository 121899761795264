@import '../../../styles/variables.scss';

.prospect-tasks {
	display: flex;
	width: 100%;

	&__main {
		flex: 2;
	}
}

.prospect-widget {
	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	background: #ffffff;
	padding: 24px 32px;
	margin-bottom: 32px;

	&--full-length {
		height: calc(100% - 32px);
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		font-weight: 500;
		font-size: 16px;
		line-height: 24px;

		display: flex;
		align-items: center;
		letter-spacing: 0.3px;
		color: $grey8;

		padding-bottom: 24px;
	}

	&__content {
	}

	&__tag {
		margin-bottom: 8px;
	}
}

.watchlist-tasks {
	padding-top: 24px;
}

.task-modal {
	p {
		padding-bottom: 24px;
	}

	.ap-modal-header {
		padding: 32px 32px 0;
		border-bottom: none;
	}

	.ap-modal-body {
		padding: 24px 32px 32px;
		border-bottom: none;
		overflow-y: visible;
	}

	.ap-modal-footer {
		padding: 16px 32px;
	}

	.task {
		&__wrapper {
			display: flex;
			width: 100%;
			justify-content: flex-start;
		}

		&__assignee {
			padding-bottom: 24px;
			input {
				z-index: auto;
			}
		}

		&__name {
			padding-bottom: 24px;
			input {
				z-index: auto;
			}
		}

		&__status {
			padding-bottom: 24px;
			button {
				height: 37px;
				border: 1px solid #999999;
				width: 150px;
				margin-right: 16px;
			}
		}

		&__description {
			textarea {
				border: 1px solid $grey4;
				border-radius: 1px;
				outline: none;
				position: relative;
				width: 100%;
				padding: 8px;
				resize: none;

				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 0.3px;
				color: #1a1a1a;

				&:hover,
				&:focus {
					border-color: $green2;
					outline: none;
				}

				&:active {
					border-color: $green3;
				}
			}
		}
		&__date {
			padding-bottom: 24px;

			.DayPicker-Day--today {
				color: #008f08;
			}

			.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
				color: #ffffff;
			}

			.DayPicker:not(.DayPicker--interactionDisabled)
				.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
				background-color: #def0d7;
			}

			.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
				background-color: #008f08;
			}

			.DayPicker-Day--selected {
				background-color: #008f08;
			}

			.DayPickerInput {
				width: 100%;
				input {
					border: 1px solid $grey4;
					border-radius: 1px;
					outline: none;
					position: relative;
					width: 100%;
					padding: 8px;
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 19px;
					letter-spacing: 0.3px;
					color: #1a1a1a;

					&:hover,
					&:focus {
						border-color: $green2;
						outline: none;
					}

					&:active {
						border-color: $green3;
					}
				}
			}
		}
	}
}
