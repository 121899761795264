@import '../../styles/variables.scss';

.homepage-navbar {
	a {
		text-decoration: none;
		color: inherit;
		background-color: transparent;
	}

	&__list {
		display: none;
		position: relative;
		margin: 0;
	}

	&__item.active .menu-dropdown__label {
		color: #ffffff;

		.menu-dropdown__label:hover {
			color: $green1;
			cursor: pointer;
		}

		.menu-dropdown__label:hover + .menu-dropdown__icon svg {
			color: $green1;
		}

		.menu-dropdown__icon svg:hover {
			fill: $green1;
		}
	}

	&__item {
		display: inline-flex;
		align-items: center;
		color: #999999;
		user-select: none;
		margin-right: 24px;

		&:first-child {
			padding-right: 8px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.menu-dropdown__label,
	&__link {
		font-size: 16px;
		line-height: 24px;
		font-weight: bold;
		outline: 0;
		display: inline-flex;
		align-items: center;
		transition: color cubic-bezier(0.19, 1, 0.22, 1);
		color: inherit;
		text-decoration: none;
		padding: 0 16px;
		height: 64px;
	}

	&__link:hover {
		color: $green1;
	}

	&__link:focus,
	&__link.active {
		color: white;
		border-bottom: 2px solid #ffffff;
	}

	&__link + &__link {
		margin-left: 16px;
		margin-right: 24px;
	}

	/* Make left part narrow */
	& > div > div:first-child {
		max-width: 150px;
	}

	&__nav-link {
		display: flex;
		align-self: stretch;
		align-items: center;
	}

	&__item.my-engagements {
		cursor: pointer;
		color: white;
		font-size: 16px;
		font-weight: bold;
		line-height: 24px;
		margin-right: 32px;
	}
}

@media (min-width: 960px) {
	.homepage-navbar__list {
		display: inline-flex;
	}
}
