@import '../../styles/variables.scss';

.active-filters {
	&__custom-chip {
		text-transform: none !important;
		color: $grey7 !important;
		letter-spacing: 0.8px !important;
		margin-right: 8px;
		margin-bottom: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
}
