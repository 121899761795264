@import '../../../styles/variables.scss';

.cashflow-section {
	&__container {
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		.ap-simple-table-container {
			height: 100% !important;
		}

		.row-cashGeneratedFromOperations,
		.row-cashGeneratedFromInvesting,
		.row-cashGeneratedFromFinancing,
		.row-netInCashAndEquivalents {
			background-color: $grey2;
			border-color: $grey2;
		}
	}

	&__empty {
		font-size: 11px;
		line-height: 13px;
		text-align: center;
		letter-spacing: 0.8px;
		text-transform: uppercase;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		left: 0;
	}
}
