@import '../../../styles/variables.scss';

.dashboard-section {
	margin-top: 32px;
	padding: 24px 32px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	background-color: $white;
	position: relative;
	min-height: 200px;

	&__title {
		font-size: 28px;
		line-height: 35px;
		letter-spacing: 0.3px;
		font-weight: 500;
		color: $black;
	}

	&__spinner {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
	}
}
