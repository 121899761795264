@import '../../../styles/variables.scss';

.financials-form {
	&__title {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.3px;
		color: $grey8;
	}

	&__separator {
		width: 100%;
		height: 1px;
		background-color: #cccccc;
		margin-bottom: 24px;
	}

	&__dropdown {
		button {
			height: 40px;
			border: 1px solid #999999;
			width: 100%;
			&:hover {
				border: 1px solid $green2;
			}

			> span {
				font-size: 16px;
			}
		}
	}

	&__alert {
		display: flex;
		align-items: center;
		height: 46px;
		width: 100%;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;
		color: $grey8;
		background-color: #e6e6e6;
		margin-top: 20px;
		margin-bottom: 20px;

		.ap-icon {
			margin: 0 8px;
		}
	}

	&__label {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #737373;
		margin-bottom: 4px;
	}

	// &__textarea {
	// 	border: 1px solid $grey4;
	// 	border-radius: 1px;
	// 	outline: none;
	// 	position: relative;
	// 	width: 100%;
	// 	padding: 8px;
	// 	resize: none;

	// 	font-family: Roboto;
	// 	font-style: normal;
	// 	font-weight: normal;
	// 	font-size: 16px;
	// 	line-height: 19px;
	// 	letter-spacing: 0.3px;
	// 	color: #1a1a1a;

	// 	&:hover,
	// 	&:focus {
	// 		border-color: $green2;
	// 		outline: none;
	// 	}

	// 	&:active {
	// 		border-color: $green3;
	// 	}
	// }

	&__row {
		display: flex;
		margin-bottom: 24px;

		&--textarea {
			flex-direction: column;
			> div:last-child {
				margin-left: 0 !important;
			}
		}

		> div:first-child {
			flex: 1;
			margin-right: 20px;
		}

		> div:last-child {
			flex: 1;
			margin-left: 20px;
		}
	}
}
