@import '../../../styles/variables.scss';

.debt-section {
	&__container {
		display: flex;
		flex-direction: column;
	}

	&__chart {
		flex: 1;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

		overflow: auto;
	}

	&__wrapper {
		display: flex;
		&:first-child {
			margin-bottom: 32px;
		}
	}

	&__description {
		margin: 24px 0 32px;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $black;
	}

	&__empty {
		font-size: 11px;
		line-height: 13px;
		text-align: center;
		letter-spacing: 0.8px;
		text-transform: uppercase;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		left: 0;
	}
}
