@import '../../styles/variables.scss';

.sidebar {
	padding: 24px 16px 0 0;
	background-color: $white;
	border-right: 1px solid $grey3;
	min-height: 100%;

	&__header {
		color: $grey7;
		font-size: 19px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 27px;
		padding: 24px 0 16px 0;
	}
}
