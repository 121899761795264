@import '../../../styles/variables.scss';

.cash-position-widget {
	min-width: 200px;
	background: $white;
	padding: 16px 20px;

	&__note {
		margin-bottom: 0 !important;
		margin-top: 8px !important;
	}
}
