@import '../../styles/variables.scss';

.prospect-content {
	margin-top: 32px;
}

.prospect {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	padding: 32px 32px 0 32px;
	background-color: $white;
	box-shadow: $box-shadow1;
	position: relative;
	width: 100%;

	&__wrapper {
		display: flex;
		width: 100%;
	}

	&__description {
		flex: 2;
		display: flex;

		.readMoreText {
			color: $green1 !important;
			font-weight: bold;
			letter-spacing: 0.8px;
			line-height: 19px;
		}
	}

	&__logo {
		max-width: 163px;
		max-height: 163px;
		filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
		border-radius: 1px;
		margin-right: 32px;
	}

	&__actions {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__name-wrapper {
		display: flex;
		justify-content: space-between;
		h1 {
			font-weight: 500;
			font-size: 28px;
			line-height: 35px;
			letter-spacing: 0.304348px;
			padding-bottom: 16px;
		}
	}

	&__notes {
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #000000;
	}

	&__title {
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;
		color: $grey5;
		padding-bottom: 32px;

		&__link:hover {
			text-decoration: underline;
			cursor: pointer;
			color: $grey8;
		}
	}

	&__name {
		color: $grey8;
	}

	&__h1 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0.3px;
		color: $grey8;
	}

	&__h2 {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		padding-bottom: 8px;
		color: $grey5;
	}

	&__heading-wrapper {
		display: flex;
		align-items: center;
		padding-bottom: 24px;
		justify-content: space-between;
	}

	&__description-wrapper {
		min-height: 94px;
		cursor: pointer;
		&:hover {
			.prospect__description__edit-icon {
				visibility: visible;
			}

			.prospect__description {
				text-decoration: underline;
				text-decoration-color: $grey3;
			}
		}
	}

	&__description {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey8;
		position: relative;

		&__edit {
			height: 20px;
			position: absolute;
			left: -26px;
			top: -3px;
		}

		&__edit-icon {
			transition: none;
			visibility: hidden;
		}
	}

	&__line {
		height: 1px;
		background-color: $grey3;
		width: 100%;
		margin-top: 32px;
		margin-bottom: 24px;
	}

	&__info {
		padding-top: 32px;
		display: flex;
	}

	&__status {
		flex: 1;
	}

	&__date {
		flex: 1;

		&__value {
			min-height: 36px;
			display: flex;
			align-items: center;
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.3px;
			color: #1a1a1a;
		}
	}

	&__progress {
		flex: 5;
	}

	&__owner {
		margin-right: 8px;
	}

	&__companies {
		// .ap-simple-table-body-cell {
		// 	padding: 0;
		// 	cursor: pointer;
		// 	border: 0;
		// }

		// .ap-simple-table-row {
		// 	&:hover {
		// 		> td {
		// 			background-color: $grey1;
		// 		}
		// 	}
		// }

		// .cell-wrapper {
		// 	padding: 12px;
		// 	cursor: pointer;
		// }
		padding-bottom: 40px;

		.ap-simple-table-container {
			height: 100%;
		}

		.ap-simple-table-body-cell {
			padding: 0;
			cursor: pointer;
			border: 0;
		}

		.ap-simple-table-row {
			&:hover {
				> td {
					background-color: $grey1;
				}
			}
		}

		.cell-wrapper {
			min-height: 40px;
			padding: 12px;
			cursor: pointer;
			border-bottom: 1px solid $grey3;
		}
	}
}
