@import '../../styles/variables.scss';

.engagements {
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
	}

	&__link {
		color: $grey7;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: 24px;
		text-decoration: none;

		&:hover {
			color: $green1;
			text-decoration: underline;
		}
	}


	&__ca-link {
		display: flex;
	}

	&__chart {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-around;

		font-style: normal;
		font-weight: 500;

		line-height: 31px;
		letter-spacing: 0.3px;

		&__type {
			font-size: 23px;
			display: flex;
			justify-content: center;
			color: $grey4;
			cursor: pointer;
		}

		&__count {
			font-size: 18px;
			display: flex;
			justify-content: center;
		}

		&__year {
			min-height: 31px;
			display: flex;
			justify-content: center;
			text-align: center;
		}
	}
}
