@import '../../styles/variables.scss';

.analysis {
	&__title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 23px;
		line-height: 31px;
		letter-spacing: 0.3px;
		color: $grey8;
		padding-bottom: 16px;
	}

	&__section {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
		background-color: $white;
		// margin-bottom: 32px;
		min-height: 603px;
		position: relative;
	}
	// &__filter {
	// 	display: flex;
	// 	margin-bottom: 24px;

	// 	&__item {
	// 		background-color: $white;
	// 	}
	// }

	// &__wrapper {
	// 	display: flex;
	// }

	// &__comments {
	// 	display: flex;
	// 	background-color: $white;
	// 	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	// 	flex-direction: column;
	// 	padding: 32px;
	// }

	// &__header {
	// 	font-weight: 500;
	// 	font-size: 19px;
	// 	line-height: 27px;
	// 	color: $black;
	// 	display: flex;
	// 	justify-content: space-between;
	// 	width: 100%;
	// }

	// &__comment {
	// 	display: flex;
	// 	padding-top: 16px;
	// 	&:hover {
	// 		.analysis__comment__actions {
	// 			display: flex;
	// 		}
	// 	}
	// 	&--obsolete {
	// 		.analysis__comment__avatar,
	// 		.analysis__comment__title,
	// 		.analysis__comment__date,
	// 		.analysis__comment__text,
	// 		.analysis__comment__tag {
	// 			opacity: 0.2;
	// 		}
	// 	}
	// 	&__title {
	// 		font-family: Roboto;
	// 		font-style: normal;
	// 		font-size: 16px;
	// 		line-height: 24px;
	// 		letter-spacing: 0.3px;
	// 		color: $grey8;
	// 		margin-right: 16px;
	// 	}
	// 	&__tags {
	// 		padding-bottom: 24px;
	// 	}
	// 	&__date {
	// 		color: $grey5;
	// 		font-family: Roboto;
	// 		font-style: normal;
	// 		font-weight: normal;
	// 		font-size: 13px;
	// 		line-height: 19px;
	// 		letter-spacing: 0.3px;
	// 	}
	// 	&__text {
	// 		font-style: normal;
	// 		font-weight: normal;
	// 		font-size: 13px;
	// 		line-height: 19px;
	// 		letter-spacing: 0.3px;
	// 		color: $grey8;
	// 		padding-bottom: 24px;
	// 	}
	// 	&__avatar {
	// 		width: 32px;
	// 		margin-right: 16px;

	// 		img {
	// 			max-width: 100%;
	// 			max-height: 100%;
	// 		}
	// 	}
	// 	&__actions {
	// 		position: absolute;
	// 		right: 0;
	// 		display: none;
	// 		div {
	// 			margin-right: 16px;
	// 		}

	// 		div:last-child {
	// 			margin-right: 0;
	// 		}

	// 		div {
	// 			cursor: pointer;
	// 		}
	// 	}
	// 	&__wrapper {
	// 		display: flex;
	// 		align-items: center;
	// 		margin-bottom: 8px;
	// 		position: relative;
	// 	}
	// 	&__content {
	// 		flex-direction: column;
	// 		display: flex;
	// 		flex: 1;
	// 		border-bottom: 1px dashed $grey3;
	// 	}
	// }
}

// .analysis-modal {
// 	.ap-modal-header {
// 		padding: 32px 32px 0;
// 		border-bottom: none;
// 	}

// 	.ap-modal-body {
// 		padding: 32px 32px 0 32px;
// 		border-bottom: none;
// 		overflow: auto;
// 	}

// 	.ap-modal-footer {
// 		padding: 16px 32px;
// 	}

// 	&__body {
// 		font-family: Roboto;
// 		font-style: normal;
// 		font-weight: normal;
// 		font-size: 13px;
// 		line-height: 19px;
// 		letter-spacing: 0.3px;
// 		color: $grey8;
// 	}

// 	&__types {
// 		display: flex;
// 		height: 40px;
// 		margin-bottom: 8px;
// 		// padding-bottom: 16px;

// 		label {
// 			padding-right: 16px;
// 		}

// 		button > span {
// 			max-width: 205px;
// 			white-space: nowrap;
// 			overflow: hidden;
// 			text-overflow: ellipsis;
// 		}
// 	}

// 	&__section-type {
// 		color: $grey8;
// 		white-space: nowrap;
// 		font-size: 13px;
// 		letter-spacing: 0.3px;
// 		line-height: 38px;
// 		padding: 2px 0 0 4px;
// 	}

// 	&__title {
// 		display: flex;
// 		padding-bottom: 16px;

// 		> div {
// 			width: 100%;
// 		}
// 	}

// 	&__analysis {
// 		display: flex;
// 		padding-bottom: 16px;
// 	}

// 	&__tagging {
// 		display: flex;
// 		flex-direction: column;
// 		padding-bottom: 16px;
// 		&__search {
// 			// width: 75%;
// 			display: flex;
// 			align-items: center;
// 			button {
// 				margin-left: 8px;
// 			}
// 		}
// 		&__tags {
// 			min-height: 64px;
// 			div {
// 				margin-top: 8px;
// 			}
// 		}
// 	}

// 	&__label {
// 		display: block;
// 		font-style: normal;
// 		font-weight: normal;
// 		font-size: 13px;
// 		line-height: 19px;
// 		letter-spacing: 0.3px;
// 		color: $grey5;
// 		padding-bottom: 8px;

// 		&--no-padding {
// 			padding-bottom: 0;
// 		}
// 	}

// 	textarea {
// 		border: 1px solid $grey4;
// 		border-radius: 1px;
// 		outline: none;
// 		position: relative;
// 		width: 100%;
// 		padding: 8px;
// 		resize: none;

// 		font-family: Roboto;
// 		font-style: normal;
// 		font-weight: normal;
// 		font-size: 16px;
// 		line-height: 24px;
// 		color: $grey8;

// 		&:hover,
// 		&:focus {
// 			border-color: $green2;
// 			outline: none;
// 		}

// 		&:active {
// 			border-color: $green3;
// 		}
// 	}
// }
