@import '../../styles/variables.scss';

.share-price {
	// min-width: 745px;
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
	}

	&__note {
		color: $grey5;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
	}

	&__value {
		padding-left: 8px;
		color: $grey7;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;

		&--up {
			color: $green1;
		}

		&--down {
			color: $red1;
		}
	}
}
