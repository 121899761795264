@import '../styles/variables.scss';

// These gutter widths are to allow for the odd 40px padding on ApAppBar, they do correspond to the gutters in gridConfig.ts but are not the same
$gutter-xs: 8px;
$gutter-sm: 8px;
$gutter-md: 24px;
$gutter-lg: 24px;
$gutter-xl: 16px;
$gutter-xxl: 16px;

$max-width: 1600px;
$min-height: 300px;

.app {
	min-height: $min-height;
	.max-width {
		margin: 0 auto;
		max-width: $max-width;
	}

	.fluid {
		max-width: 100%;
	}

	.fluid {
		padding: 0px $gutter-xs;
	}

	.debug {
		outline: 1px solid red;
	}
}

.row-gap {
	&-xs {
		margin-bottom: $space-xs;
	}
	&-sm {
		margin-bottom: $space-sm;
	}
	&-md {
		margin-bottom: $space-md;
	}
	&-lg {
		margin-bottom: $space-xl;
	}
	&-xl {
		margin-bottom: $space-xxl;
	}
}

@media (min-width: $sm) {
	.app {
		.fluid {
			padding: 0px $gutter-sm;
		}

		.debug {
			outline: 1px solid blue;
		}
	}
}

@media (min-width: $md) {
	.app {
		.fluid {
			padding: 0px $gutter-md;
		}
		.debug {
			outline: 1px solid green;
		}
	}
}

@media (min-width: $lg) {
	.app {
		.fluid {
			padding: 0px $gutter-lg;
		}
		.debug {
			outline: 1px solid orange;
		}
	}
}

@media (min-width: $xl) {
	.app {
		.fluid {
			padding: 0px $gutter-xl;
		}
		.debug {
			outline: 1px solid hotpink;
		}
	}
}
