@import '../../styles/variables.scss';

.pitch-captains {
    margin-bottom: 16px;

    div {
        color: $grey7;
        font-weight: $font-weight-regular;        
    }

    .engagement-type-titles {
            display:grid;
            grid-template-columns: repeat(3, 1fr);            

            .type {                
                font-size: 19px;
                font-weight: bold;
                display: flex;                
                color: $grey4;
                cursor: pointer;
                margin-bottom: 5px;
                justify-content: center;
            }    
            
            .count {
                font-size: 18px;
                display: flex;
                justify-content: center;
                color: $grey7;
                font-weight: bold;
                margin-bottom: 10px;
            }            
    }    

    .no-expand {
        display: none;
    }

    .expand {
        display: block;
    }    

    .title {        
        font-weight: $font-weight-bold;
        font-size: medium;
        text-align: center;
        margin-top: 22px;
        margin-bottom: 8px;
        padding:5px;
        padding-bottom:10px;
        padding-top:10px;        
        border-bottom: solid 1px $grey3;
    }

    .engagement-types {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;

        div {
            font-size: small;
            margin-top:5px;
            text-align: left;
            padding-left: 10px;
        }        
        /*:nth-child(1), :nth-child(2) { border-right: $grey2 1px solid }*/
    }

    .expand-btn {        
        display: block;
        margin-left: 5px;
    }            

    .years-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .years1, .years2, .years3 {
            font-size: small;
            text-align: center;
        }
    }    
}