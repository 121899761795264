@import '../../../styles/variables.scss';

.peers-bar-chart {
	background-color: $grey3;

	&__wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(450px, auto));
		gap: 1px 1px;

		// Hack for extra gap
		width: calc(100% + 1px);
	}

	&__chart {
		background-color: $white;
		padding: 16px;
	}
}
