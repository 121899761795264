@import '../../styles/variables.scss';

.geography {
	// min-height: 784px;
	// min-width: 745px;
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;

	// To fix zoom issue
	.highcharts-container {
		pointer-events: none;
	}

	.hq-pin {
    border-radius: 50%;
    background-color: #5cb335;
    width: 15px;
    height: 15px;
    border: solid 2px white;
    position: absolute;
    top: -27px;
    left: -5px;

		div {
			position: absolute;
			background-color: #5cb335;
			width: 5px;
			height: 14px;
			right: 3px;
			border-bottom: solid 2px white;
			border-right: solid 2px white;
			border-left: solid 2px white;
			top: 11px;
		}
	}

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
	}

	&__sub-title {
		color: $grey7;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: 24px;
		padding-bottom: 24px;
	}

	&__label {
		color: $grey5;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
		text-transform: uppercase;
		padding-bottom: 4px;
	}

	&__countries {
		display: flex;
		flex-wrap: wrap;
	}

	&__country {
		color: $grey7;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		flex-basis: 160px;
		padding-bottom: 8px;
	}

	&__company-data {
		padding-top: 48px;
		display: flex;

		&__column {
			flex: 1;

			p: {
				color: $grey8;
				font-size: 13px;
				letter-spacing: 0.8px;
				line-height: 19px;
			}
		}
	}
}
