@import '../../../styles/variables.scss';

.quarterly-chart {
	background: $white;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	padding: 16px 20px;
	height: 352px;

	&__note {
		display: flex;
		justify-content: space-evenly;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $black;
	}

	&__title {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;

		letter-spacing: 0.8px;
		text-transform: uppercase;

		color: $grey8;
		padding-bottom: 8px;
	}

	&__data {
	}
}
