@import '../../styles/variables.scss';

.company-container {
	background-color: $white;
	padding: 0 40px;
}

// TODO: move into charts components
.custom-tooltip {
	background-color: $white;
	letter-spacing: 0.8px;
	line-height: 19px;
	font-family: $font-family;
	color: $grey8;
	font-size: 13px;
	padding: 16px;
	min-width: 270px;
	box-shadow: $box-shadow2;

	&--share-price {
		min-width: 170px;
	}

	&__point {
		font-size: 20px;
	}
	&__yvalue {
		color: $grey7;
		font-weight: $font-weight-bold;
	}
}
