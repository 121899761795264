@import '../../styles/variables.scss';

.category-margins {	
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;
	width: 100%;
	height: fit-content;
	animation: control-panel-fade-in 0.5s 0.5s both;
	

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: $space-md;
	}

	&__hr {
		background-color: $grey2;
		width: 100%;
		display: block;
		padding: 0;
		margin: 0 0 $space-md;
		height: 1px;
	}

	&__info {
		color: $grey5;
		font-size: 13px;
		letter-spacing: 0;
		line-height: 16px;
		margin-bottom: 16px;
	}

	&__label {
		color: $grey5;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
		text-transform: uppercase;
		padding-bottom: 8px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&--invalid {
			color: $red1;
		}

		span.color-block {
			width: 12px;
			height: 12px;
			display: block;
			margin: 0 4px 0 0;
		}
	}

	@keyframes marginFadeIn {
		from { opacity:0 }
		to { opacity:1 }
	}

	$marketDataCol: #102E43;

	&__label-box-sales {
		background-color: $red2;		
	}

	&__label-box-profitability {
		background-color: $blue;		
	}

	&__label-box-solvency {
		background-color: $green4;		
	}

	&__label-box-working-capital {
		background-color: $amber;		
	}

	&__label-box-market-data {
		background-color: $marketDataCol;		
	}

	$delay-start: 0.75s;

	&__margin {
		height: 100%;
		transition-duration: 0.5s;
		animation: 1s both marginFadeIn;
	}

	&__margin-sales {
		background-color: $red2;
		animation-delay: $delay-start;
	}

	&__margin-profitability {
		background-color: $blue;
		animation-delay: $delay-start + 0.1s;
	}		

	&__margin-solvency {
		background-color: $green4;
		animation-delay: $delay-start + 0.2s;
	}	

	&__margin-working-capital {
		background-color: $amber;
		animation-delay: $delay-start + 0.3s;
	}

	&__margin-market-data {
		background-color: $marketDataCol;
		animation-delay: $delay-start + 0.4s;
	}

	&__margin-difference {
		background-color: $grey2;
		animation: none;
	}

	&__error {
		padding: 16px 0 16px;
		color: $red1;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
		padding-bottom: 8px;
	}

	&__graph {
		margin: 32px 0 16px;
		width: 100%;
		background-color: $white;
		height: 48px;
		display: flex;
	}

	&__margin1 {
		background-color: $amber;
		height: 100%;
	}

	&__margin2 {
		background-color: $blue;
		height: 100%;
	}

	&__margin3 {
		background-color: $red2;
		height: 100%;
	}

	&__margin4 {
		background-color: $green4;
		height: 100%;
	}

	&__margin5 {
		background-color: $amber2;
		height: 100%;
	}

	&__input {
		&--invalid {
			border: 1px solid $red1;
		}
	}	

	&__form {
		display: flex;
		width: 100%;
		justify-content: space-around;
	}
}
