@import '../../../styles/variables.scss';

// TODO: move to comparison css
.peers-share-price {
	&__legend {
		padding-top: 32px;

		display: flex;
		width: 100%;
		justify-content: center;

		&__item {
			display: flex;
			padding-right: 24px;
			align-items: center;
		}

		&__mark {
			height: 16px;
			width: 16px;
			border-radius: 50%;
			display: inline-block;
			margin-right: 8px;
		}

		&__value {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: normal;
			font-size: 11px;
			line-height: 13px;

			color: $grey8;
		}
	}

	// Hide outline and border for active input
	.highcharts-range-selector:focus {
		outline: none;
		transform: translate(2px, 1px);
		border-color: transparent !important;
	}

	// Manually move actual text node in center
	.highcharts-range-input {
		text {
			transform: translate(0px, 10px);
		}
	}

	// Manually move label
	.highcharts-range-label {
		text {
			transform: translate(-4px, 10px);
		}
	}

	// Align buttons and label
	.highcharts-range-selector-buttons {
		transform: translate(0px, 12px);
		> text {
			transform: translate(0px, 8px);
		}
	}

	.highcharts-button {
		cursor: pointer;
	}

	.tooltip {
		background-color: $white;
		letter-spacing: 0.8px;
		line-height: 19px;
		font-family: $font-family;
		color: $grey8;
		font-size: 13px;
		padding: 16px;
		min-width: 270px;
		box-shadow: $box-shadow2;

		min-width: 170px;

		&__point {
			font-size: 20px;
		}
		&__value {
			color: $grey7;
			font-weight: $font-weight-bold;
		}
	}
}
