@import '../../styles/variables.scss';

.wrapper-screening {
	padding: 0;
}

@keyframes control-panel-fade-in {
	from { opacity: 0; transform: translate(0, 100%); }
	to { opacity: 1; transform: translate(0, 0); }
}

.screening-container {
	// display: flex;
	// width: 100%;
	// height: 100%;
	padding-top: 44px;
	display: flex;
	min-height: calc(100vh - #{$header-height});
	// margin-top: 112px !important;

	iframe {
		width: 100%;
		height: 100%;
		min-height: calc(100vh - #{$header-extra-height});
	}
}

.control-panel-container {
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - #{$header-height});
	margin-top: 154px !important;
	padding: 0 32px 32px;

	> div {
		margin-bottom: 42px;
	}
}

.control-modal {
	&__footer {
		display: flex;
		justify-content: center;
	}

	&__content {
		color: $grey5;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
	}
}

.category-margins-loader {
	margin-top: 100px;
}

.apply-button-container {
	.ap-button-main {
		margin-left: 8px;
	}
}

.find-out-more {
	margin-bottom:24px;	
}

.cat-name-span {
	text-transform: capitalize;
}

.kpi-intro {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;

	.left-intro {
		display: grid;
		grid-template-columns: 2fr 1fr;

		> div:nth-child(2) {						
			justify-items: space-around;			
		}
	}
	
	> div:nth-child(1) {
		border-right: solid 1px $grey5;
		padding-right: 50px;
	}

	> div:nth-child(2) {
		padding-left: 50px;
	}
}

.want-to-see {
	margin-top: 16px;
	margin-bottom: 8px;
}



.find-out-container {
	display: grid;
	grid-template-columns: 1fr 1fr;		
}

.adjust-msg {
	position:relative;
	top:15px;
}