@import '../../../styles/variables.scss';

.company-count {
	// height: 200px;
	// width: 100%;
	display: flex;
	flex-direction: column;
	// max-width: 700px;
	// min-width: 350px;

	// display: block;
	width: 100%;
	// height: 200px;


	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	padding: 16px 24px;

	&__title {
		font-weight: 500;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey8;
		padding-bottom: 16px;
	}

	&__data {
		display: flex;
		padding-bottom: 10px;

		> div {
			flex: 1;
		}

		&__label {
			font-size: 11px;
			line-height: 13px;
			color: $grey5;
		}

		&__value {
			font-weight: bold;
			font-size: 23px;
			line-height: 31px;
			letter-spacing: 0.3px;
			color: #333333;
		}
	}
}
