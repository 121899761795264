@import '../../styles/variables.scss';

.connections {
	// min-height: 784px;
	// min-width: 745px;
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;

	&__title-wrapper {
		display: flex;
		padding-bottom: 24px;
		align-items: center;
		justify-content: space-between;
	}

	&__link {
		padding-bottom: 16px;
	}

	&__controls {
		display: flex;
		cursor: pointer;
		// position: absolute;
		// top: 30px;
		// right: 70px;

		&__chart,
		&__table {
			&--active {
				path {
					fill: $grey6;
				}
			}
			:hover {
				path {
					fill: $green2;
				}
			}
		}

		&__table {
			margin-left: 16px;
		}
	}

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		// padding-bottom: 24px;
	}

	&__legend {
		padding-bottom: 40px;
		color: $grey5;
		font-size: 13px;
		letter-spacing: 0.3px;
		line-height: 19px;
		display: flex;

		&__item {
			display: flex;
			align-items: center;

			&:last-child {
				margin-left: 24px;
			}

			svg {
				margin-right: 8px;
			}
		}
	}

	&__table {
		border: 1px solid $grey2;
		width: 100%;
		display: flex;
		flex-direction: row;
		color: $grey7;
		font-size: 13px;
		letter-spacing: 0.8px;
		line-height: 19px;

		min-height: 200px;

		&__relation {
			padding-bottom: 8px;
		}

		&__ap-employee {
			display: flex;
			flex-direction: row;
			padding: 16px 0 24px 0;
			border-bottom: 1px solid $grey2;

			&:last-child {
				border-bottom: none;
			}

			> div {
				padding-left: 16px;
				flex: 1;
			}
		}

		&__header {
			color: $grey5;
			font-size: 11px;
			letter-spacing: 0;
			line-height: 13px;
			padding: 8px 16px;
			text-transform: uppercase;
			background-color: $grey1;
		}

		&__vendor {
			display: flex;
			flex: 1;
			flex-direction: column;
			border-left: 1px solid $grey2;
		}

		&__vendor-name {
			padding-left: 16px;
			align-items: center;
			height: 100%;
			display: flex;
		}

		&__header-wrapper {
			display: flex;
			width: 100%;

			> div {
				flex: 1;
			}
		}

		&__relationship {
			display: flex;
			flex-direction: column;
			flex: 2;
		}
	}

	&__load-more {
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		justify-content: center;
		z-index: 100;
		padding: 10px;
		background-color: $white;
	}

	&__chart {
		display: flex;
		margin-bottom: 30px;
		// height: 670px;
		overflow: hidden;

		&--expanded {
			height: 100%;
			overflow: auto;
		}

		&__wrapper {
			position: relative;
			margin-bottom: 16px;
			height: 48px;
		}

		&__header {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 48px;
			background-color: $grey1;
			text-transform: uppercase;
			color: $grey7;
			font-size: 13px;
			font-weight: bold;
			letter-spacing: 0.8px;
			line-height: 19px;

			&--vendor {
				right: -24px;
				left: -16px;
			}

			&--ap {
				left: -24px;
				right: -16px;
			}

			.engagement-connector {
				z-index: 1;
				position: absolute;
				top: 18px;
				right: -7px;
			}
		}

		&__vendor {
			flex: 1;
			// flex-shrink: 0;
			padding-left: 16px;
		}

		&__ap {
			flex: 1;
			// flex-shrink: 0;
			padding-right: 16px;
		}

		&__relationship {
			position: relative;
			flex-basis: 105px;
			flex-shrink: 0;
			flex-grow: 0;

			.hovered {
				position: absolute;
				left: 0;
			}

			.relationship-line {
				cursor: pointer;
			}
		}

		.employee {
			cursor: pointer;
			// width: 280px;
			height: 100px;
			padding: 16px 24px;
			margin-bottom: 24px;
			position: relative;
			outline: 2px solid #eee;

			&--hovered {
				outline: 2px solid #6babc7;
				box-shadow: $box-shadow2;
				svg {
					circle {
						fill: #6babc7;
					}
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&__name {
				color: $grey7;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0.3px;
				line-height: 24px;
			}

			&__role {
				color: $grey5;
				font-size: 13px;
				letter-spacing: 0.8px;
				line-height: 19px;
			}

			&__connector {
				z-index: 1;
				position: absolute;
				top: 44px; // 0.5 * height - half of svg height

				&--right {
					right: -7px;
				}

				&--left {
					left: -7px;
				}

				&--hovered {
					z-index: 2;
				}
			}

			&__email {
				a {
					color: $green1;
					font-size: 13px;
					letter-spacing: 0.8px;
					line-height: 19px;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
