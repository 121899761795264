@import '../../../styles/variables.scss';

.score {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-around;

	&__circle {
		border: 5px solid $white;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 33px;
		line-height: 41px;
		letter-spacing: 0.3px;
		color: $white;
		flex-grow: 1;

		height: 10vw;
		width: 10vw;
		max-width: 100px;
		max-height: 100px;
	}

	&__overall {
		@extend .score__circle;
		background-color: #008f0a;
	}
	&__capital {
		@extend .score__circle;
		background-color: #ff910f;
	}
	&__profit {
		@extend .score__circle;
		background-color: #032e45;
	}
	&__sales {
		@extend .score__circle;
		background-color: #77b0be;
	}
	&__solvency {
		@extend .score__circle;
		background-color: #ee3a36;
	}
	&__market {
		@extend .score__circle;
		background-color: #77b0be;
	}

	&__green {
		@extend .score__circle;
		background-color: #008f0a;
	}
	&__amber {
		@extend .score__circle;
		background-color: #ff910f;
	}
	&__red {
		@extend .score__circle;
		background-color: #ee3a36;
	}

	&__separator {
		display: flex;
		width: 1px;
		height: 64px;
		background-color: #cccccc;
	}

	&__label {
		padding-top: 8px;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.3px;
		color: $black;
	}
}
