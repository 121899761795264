@import '../../styles/variables.scss';

.comparison {
	&__title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 23px;
		line-height: 31px;
		letter-spacing: 0.3px;
		color: $grey8;
		padding-bottom: 16px;
	}

	&__wrapper {
		display: flex;
		width: 100%;
	}

	&__search {
		margin-right: 8px;
		flex-basis: 300px;

		div {
			margin-bottom: 0;
		}

		.max-number-info {
			font-size: 12px;

			&.warning {
				color: rgba(240, 78, 78, 0.6);
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		flex: 1;
	}

	&__peer {
		background-color: $white;
		margin-bottom: 8px;
	}

	&__spinner {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
	}

	&__container {
		margin-top: 24px;
	}

	&__loading-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;

		.ap-loader-default {
			z-index: 101;
		}
	}

	&__section {
		padding: 24px 32px;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
		background-color: $white;
		margin-bottom: 32px;
		min-height: 603px;
		position: relative;

		&__data {
			position: relative;
		}

		&__separator {
			width: 100%;
			height: 1px;
			background-color: $grey3;
			// transform: translate(0, 7px);
		}

		&__nav {
			margin-top: 24px;
			// padding-top: 8px;
			border-top: 1px solid $grey3;
		}

		&__chart {
			// &--loading {
			// 	opacity: 0.4;
			// }
		}

		&__analysis {
			margin-top: 48px;
		}

		&__title {
			font-size: 28px;
			line-height: 35px;
			letter-spacing: 0.3px;
			font-weight: 500;
			color: $black;
		}

		&__text {
			font-size: 16px;
			line-height: 24px;
			color: $black;
			padding: 24px 0;
		}
	}
}
