@import '../../../styles/variables.scss';

.prospect-overview {
	display: flex;
	width: 100%;

	&__main {
		flex: 2;
		padding-right: 16px;
	}

	&__ahead {
		padding-left: 16px;
		flex: 1;
	}
}

.prospect-member {
	position: relative;
	display: flex;
	background: #f2f2f2;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	margin-bottom: 16px;
	height: 100px;

	&__remove {
		display: none;
		position: absolute;
		right: 16px;
		cursor: pointer;
		top: 38px;

		&:hover {
			svg {
				fill: #333333;
			}
		}
	}

	&:hover {
		.prospect-member__remove {
			display: flex;
		}
	}

	&__avatar {
		background: #999999;
		width: 100px;
		height: 100px;

		> img {
			width: 100%;
			height: 100%;
		}
	}

	&__info {
		padding: 8px 16px;
		font-size: 11px;
		line-height: 13px;
		color: #1a1a1a;
	}

	&__name {
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;
		margin-bottom: 8px;
	}
}
.prospect-owner {
	position: relative;
	display: flex;
	background: #333333;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	margin-bottom: 16px;
	height: 100px;

	&__remove {
		display: none;
		position: absolute;
		right: 16px;
		cursor: pointer;
		top: 38px;

		&:hover {
			svg {
				fill: #f2f2f2;
			}
		}
	}

	&:hover {
		.prospect-owner__remove {
			display: flex;
		}
	}

	&__avatar {
		background: #999999;
		width: 100px;
		height: 100px;

		> img {
			width: 100%;
			height: 100%;
		}
	}

	&__info {
		padding: 8px 16px;
		font-size: 11px;
		line-height: 13px;
		color: $white;
	}

	&__name {
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;
		margin-bottom: 8px;
	}
}

.prospect-file {
	position: relative;
	display: flex;
	background: #f2f2f2;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	margin-bottom: 16px;
	height: 100px;

	&__remove {
		display: none;
		position: absolute;
		right: 16px;
		cursor: pointer;
		top: 38px;

		&:hover {
			svg {
				fill: #333333;
			}
		}
	}

	&--inactive {
		opacity: 50%;
		user-select: none;
		pointer-events: none;
	}

	&:hover {
		.prospect-file__remove {
			display: flex;
		}
	}

	&__avatar {
		background: #999999;
		width: 100px;
		height: 100px;
		min-width: 100px;

		> img {
			width: 100%;
			height: 100%;
		}
	}

	&__info {
		padding: 8px 16px;
		font-size: 11px;
		line-height: 13px;
		color: #1a1a1a;
	}

	&__name {
		width: 300px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		font-weight: 500;
		font-size: 19px;
		line-height: 27px;
		margin-bottom: 8px;

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.prospect-watchlists {
	display: flex;
	flex-direction: column;

	&__headers {
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey5;
		width: 100%;
		margin-bottom: 16px;

		display: flex;
		padding-bottom: 8px;
		border-bottom: 1px solid #cccccc;

		> div {
			flex: 1;
		}
	}

	&__items {
		display: flex;
		width: 100%;

		> div {
			flex: 1;
			align-items: center;
			display: flex;
			font-size: 16px;
			line-height: 24px;
			color: $grey8;
		}
	}
}

.linked-file {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	border: 1px solid #999999;
	border-radius: 16px;
	padding: 8px 12px;
	margin-right: 8px;
	height: 32px;
	cursor: pointer;
	text-decoration: none;

	&__remove {
		display: none;
		position: absolute;
		right: 8px;
		top: 2px;

		&:hover {
			svg {
				fill: #333333;
			}
		}
	}

	&:hover {
		border-color: #488929;
		.linked-file__remove {
			display: flex;
		}
	}

	&__name {
		max-width: 350px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: uppercase;
		font-size: 13px;
		line-height: 13px;

		a {
			color: #1a1a1a;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.comment-modal {
	.ap-modal-header {
		padding: 32px 32px 0;
		border-bottom: none;
	}

	.ap-modal-body {
		padding: 24px 32px 32px;
		border-bottom: none;
		overflow-y: visible;
	}

	.ap-modal-footer {
		padding: 16px 32px;
	}

	&__body {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey8;
	}

	&__types {
		display: flex;
		height: 40px;
		margin-bottom: 8px;

		label {
			padding-right: 16px;
		}

		button > span {
			max-width: 205px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__analysis {
		display: flex;
		padding-bottom: 16px;
	}

	&__label {
		display: block;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey5;
		padding-bottom: 8px;

		&--no-padding {
			padding-bottom: 0;
		}
	}

	textarea {
		border: 1px solid $grey4;
		border-radius: 1px;
		outline: none;
		position: relative;
		width: 100%;
		padding: 8px;
		resize: none;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #1a1a1a;

		&:hover,
		&:focus {
			border-color: $green2;
			outline: none;
		}

		&:active {
			border-color: $green3;
		}
	}
}

.edit-financials {
	.ap-modal-body {
		padding: 30px;
	}

	.ap-modal-footer {
		border-top: none;
	}

	.ap-modal-body {
		overflow-y: visible;
	}
}

.edit-debt {
	.ap-modal-body {
		padding: 16px 0 16px 16px;
	}
}

.prospect-widget {
	position: relative;

	&--no-padding {
		padding: 24px 0px 0px !important;
	}

	&--focused {
		outline: 1px solid $green1;

		.prospect-widget__drop-area {
			opacity: 40%;
		}

		.prospect-widget__drop-message {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__drop-message {
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;
		// margin-bottom: 8px;
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
	}

	&__drop-message {
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
	}

	.ap-loader-default {
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		bottom: 48px;
	}

	&__timestamp {
		position: absolute;
		top: 56px;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;
		color: #737373;
	}

	&--padding {
		padding-bottom: 85px !important;
	}

	.financial {
		&__debt {
			&__row {
				font-weight: normal;
				font-size: 13px;
				line-height: 19px;
				letter-spacing: 0.3px;
				color: #000000;
			}
		}

		&__separator {
			background-color: #e6e6e6;
			height: 1px;
		}
		&__label {
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.3px;
			color: $grey5;
			margin-bottom: 8px;
		}

		&__sync {
			border-top: 1px solid #e6e6e6;
			height: 73px;
			background-color: #eff8ed;
			display: flex;

			&__text {
				margin-left: 30px;
				display: flex;
				flex: 3;
				font-weight: normal;
				font-size: 11px;
				line-height: 14px;
				color: $grey8;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			&__label {
				font-weight: 500;
				font-size: 13px;
				line-height: 19px;
				letter-spacing: 0.3px;
				color: $grey8;
				margin-bottom: 5px;
			}

			&__button {
				align-items: center;
				justify-content: center;
				display: flex;
				flex: 1;
			}
		}

		&__value {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: #000000;
			margin-bottom: 32px;

			word-break: break-all;
		}

		&__row {
			display: flex;

			&--separator {
				margin-top: 24px;
				flex-direction: column;
			}

			> div {
				flex: 1;
			}
		}
	}

	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	background: #ffffff;
	padding: 24px 32px;
	margin-bottom: 32px;

	&--full-length {
		height: calc(100% - 32px);
	}

	&__info {
		font-size: 11px;
		line-height: 13px;
		color: $grey5;
		font-weight: normal;
	}

	&__title {
		height: 60px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		font-weight: 500;
		font-size: 16px;
		line-height: 24px;

		display: flex;
		align-items: center;
		letter-spacing: 0.3px;
		color: $grey8;

		padding-bottom: 24px;
	}

	&__content {
	}

	&__tag {
		margin-bottom: 8px;
	}

	&__team-modal,
	&__tag-modal {
		.ap-modal-body {
			overflow-y: visible;
			padding: 24px 16px 40px;
		}
	}
}

.watchlist-comments {
	padding-top: 8px;

	&__tabs {
		border-bottom: 1px solid #cccccc;
	}
}

.comment {
	display: flex;
	padding-top: 16px;
	&:hover {
		.comment__actions {
			display: flex;
		}
	}
	&--obsolete {
		.comment__avatar,
		.comment__title,
		.comment__date,
		.comment__review,
		.comment__text {
			opacity: 0.2;
		}
	}
	&__review {
		display: flex;
		// justify-content: flex-end;
		padding-bottom: 16px;
	}
	&__title {
		font-family: Roboto;
		font-style: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.3px;
		color: $grey8;
		margin-right: 16px;
	}
	&__tags {
		padding-bottom: 24px;
	}
	&__date {
		color: $grey5;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
	}
	&__text {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey8;
		padding-bottom: 16px;
		word-break: break-all;
	}
	&__avatar {
		width: 32px;
		margin-right: 16px;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
	&__actions {
		position: absolute;
		right: 16px;
		display: none;
		div {
			margin-right: 16px;
		}

		div:last-child {
			margin-right: 0;
		}

		div {
			cursor: pointer;
		}

		svg:hover {
			fill: #333333;
		}
	}
	&__wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		position: relative;
	}
	&__content {
		flex-direction: column;
		display: flex;
		flex: 1;
		border-bottom: 1px dashed $grey3;
	}
	&__no-comments {
		padding-top: 16px;
		padding-bottom: 24px;
		border-bottom: 1px dashed $grey3;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;

		letter-spacing: 0.3px;
		color: $grey8;
	}

	&__replies {
		margin-left: 25px;

		.comment {
			border-top: 1px dashed #CCCCCC;
		}

		.comment__content {
			border-bottom: none;
		}
	}
}

.padding-wrapper {
	padding-left: 32px;
	padding-right: 32px;
}

.flex-column {
	flex-direction: column;
}
