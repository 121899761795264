@import '../../styles/variables.scss';

.widget-wrapper {
	position: relative;
	margin-bottom: 24px;

	&__export {
		text-transform: uppercase;
		color: $grey7;
		font-family: Roboto;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: 13px;

		position: absolute;
		top: 24px;
		right: 24px;

		svg {
			fill: $grey5;
		}
	}
}

.loading-wrapper {
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;
	margin-bottom: 24px;

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
	}

	.ap-loader-default {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
	}
}
