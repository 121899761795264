@import '../../styles/variables.scss';

.widget-error {
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;
	display: flex;
	flex-direction: column;

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
		flex: 0;
	}

	&__message {
		color: $grey8;
		font-size: 13px;
		letter-spacing: 0.8px;
		line-height: 19px;
		flex: 0;
	}

	&__wrapper {
		justify-content: center;
		flex: 1;
		display: flex;
		align-items: center;
	}
}
