@import '../../styles/variables.scss';

.peers-search-wrapper {
	display: flex;
}

.peers-search {
	flex: 1;
	position: relative;
	background-color: $white;

	svg {
		z-index: 2;
	}

	.results {
		&__spinner {
			padding: 16px;
		}

		display: flex;
		position: absolute;
		background-color: $white;
		right: 0;
		left: 0;

		border: 1px solid $grey3;
		font-weight: 300;
		font-size: 13px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 2;
		max-height: calc(100vh - 300px);
		overflow: auto;

		ul {
			width: 100%;
			margin: 0px;
			padding: 0px;
			list-style-type: none;
		}

		li {
			line-height: 35px !important;

			&:hover {
				cursor: pointer;
				background-color: $grey3;
			}

			cursor: pointer;
			font-weight: 400;
			padding: 8px;
			display: list-item;
		}
	}
}
