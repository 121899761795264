@import '../../../styles/variables.scss';

.task-row {
	width: 100%;
	min-height: 101px;
	background-color: #f4f4f4;
	margin-bottom: 8px;
	padding: 24px 32px;
	position: relative;

	&:hover {
		.task-row__actions {
			display: flex;
		}
	}

	&__status-block {
		background-color: #85bddd;
		height: 8px;
		width: 80px;
		position: absolute;
		top: 0;
	}

	&__wrapper {
		display: flex;
	}

	&__label {
		font-size: 13px;
		line-height: 19px;
		display: flex;
		align-items: center;
		letter-spacing: 0.3px;
		color: #737373;
		padding-bottom: 8px;
	}

	&__desciption {
		flex: 5;
		padding-right: 24px;

		p {
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.3px;
			color: #1a1a1a;
		}

		h5 {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.3px;
			color: #1a1a1a;
			padding-bottom: 8px;
		}
	}

	&__actions {
		position: absolute;
		right: 16px;
		display: none;
		div {
			margin-right: 16px;
		}

		div:last-child {
			margin-right: 0;
		}

		div {
			cursor: pointer;
		}

		svg:hover {
			fill: #333333;
		}
	}

	&__date,
	&__owner,
	&__assignee,
	&__status {
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #1a1a1a;
		flex: 1;
	}

	&__status {
		&__dropdown {
			button {
				background-color: #f4f4f4;
				height: auto;
				padding: 0;

				span {
					line-height: normal;
					padding-top: 0;

					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 13px;
					line-height: 13px;
					letter-spacing: 0.3px;
					text-transform: uppercase;
					color: $grey8;
				}
			}
		}
	}
}
