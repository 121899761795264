@import '../../styles/variables.scss';

.multi-select {
	.multi-select {
		&__indicator-separator {
			display: none;
		}

		&__multi-value__remove {
			cursor: pointer;

			&:hover {
				background-color: #cccccc;
				color: #333333;
			}
		}

		&__control {
			border-radius: 0;
			border: 1px solid rgb(153, 153, 153);
			border-radius: 1px;

			&:hover {
				border-color: rgb(21, 114, 59);
				outline: none;
			}

			&:active {
				border-color: rgb(51, 98, 29);
			}

			box-shadow: none;
		}

		&__option {
			letter-spacing: 0.3px;
			line-height: 24px;

			&--is-focused {
				background-color: rgba(21, 114, 59, 0.1);
			}

			&:active {
				background-color: rgba(21, 114, 59, 0.3);
			}
		}

		&__menu {
			// box-shadow: $box-shadow1;
			border-radius: 0;
		}
	}
}
