@import '../../styles/variables.scss';

.kpi-panel {
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;
	width: 100%;
	animation: control-panel-fade-in 0.5s 0.75s both;

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: $space-md;		
	}

	&__info {
		color: $grey5;
		font-size: 13px;
		letter-spacing: 0;
		line-height: 16px;
		margin-bottom: 16px;
	}

	&__hr {
		background-color: $grey2;
		width: 100%;
		display: block;
		padding: 0;
		margin: 0 0 $space-md;
		height: 1px;
	}

	&__grid {
		margin: 32px 0 0;
	}
}

.ag-row .ag-cell {
	display: flex;
	align-items: center;
}
