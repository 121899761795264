@import '../../styles/variables.scss';

.dashboard {
	.link {
		color: $green1;
		font-size: 13px;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-decoration: none;
		font-weight: 500;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 28px;
		line-height: 35px;
		letter-spacing: 0.3px;
		color: $black;
	}

	&__subtitle {
		font-weight: 500;
		font-size: 23px;
		line-height: 31px;
		letter-spacing: 0.3px;
		color: $black;
	}

	p {
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $black;
		margin: 24px 0 48px;
	}

	&__summary {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__separator {
		height: 1px;
		background-color: #c4c4c4;
		width: 100%;
		margin: 32px 0 32px;
	}
}
