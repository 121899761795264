@import '../../styles/variables.scss';

.summary {
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
	}

	&__label {
		color: $grey5;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
		text-transform: uppercase;
	}

	&__value {
		margin-top: 8px;
		color: $grey7;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
	}

	&__wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		row-gap: 16px;

		> div:last-child {
			grid-column-start: 2;
		}
	}
}
