@import '../../styles/variables.scss';

.watchlist-report {
	display: flex;
	height: 100%;
	border-top: 1px solid $grey3;
	// margin-top: 24px;

	&__label {
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;
		color: $grey5;
		margin-bottom: 21px;
	}

	&__companies {
		flex: 2;
		min-width: 0;
		padding-top: 14px;
		overflow-y: auto;

		.report-company {
			display: flex;
			align-items: center;
			position: relative;

			&__checkbox {
				z-index: 1;
				transform: translateX(9px);
				position: absolute;
			}

			&__label {
				position: relative;
				padding-left: 44px;
				padding-right: 9px;
				display: flex;
				align-items: center;
				font-size: 16px;
				line-height: 24px;
				color: $grey8;
				cursor: pointer;
				height: 46px;
				width: 100%;
				user-select: none;

				&--selected {
					background-color: $green0;
				}

				> span {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}

	&__comments {
		flex: 3;
		padding: 24px 0 0 24px;
		border-left: 1px solid $grey3;

		overflow-y: auto;
		padding-right: 16px;

		label {
			display: flex;
			align-items: flex-start;

			> span:last-child {
				margin-left: 17px;
				padding-left: 0;
			}

			> .ap-checkbox {
				flex-shrink: 0;
			}
		}

		.report-comment {
			display: flex;
			flex-direction: column;
			margin-bottom: 17px;

			&__author {
				font-weight: 500;
				font-size: 13px;
				line-height: 19px;
				letter-spacing: 0.3px;
				color: $grey8;
			}

			&__date {
				margin-left: 12px;
				font-size: 13px;
				line-height: 19px;
				letter-spacing: 0.3px;
				color: $grey5;
			}

			&__text {
				margin-top: 8px;
				font-size: 11px;
				line-height: 13px;
				color: #000000;
				word-break: break-word;
			}
		}
	}
}
