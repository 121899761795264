@import '../../styles/variables.scss';

.watchlist {
	display: flex;
	flex-direction: column;
	margin-top: 24px;
	padding: 32px;
	background-color: $white;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	position: relative;
	width: 100%;

	// $table-header: #1976d2;
	// $table-header-border: #1565c0;
	$table-border: #d9d9d9;
	// $row-bg: #f4f2f1;

	div {
		box-sizing: border-box;
	}

	.table-container {
		display: block;
		// margin: 2em auto;
		// width: 90%;
		// max-width: 600px;
	}

	// .flag-icon {
	// 	margin-right: 0.1em;
	// }

	.flex-table {
		display: flex;
		flex-flow: row wrap;
		// border-left: solid 1px $table-border;

		.data {
			word-break: break-all;
		}

		color: #333333;
		font-family: 'Roboto', Helvetica, Arial, sans-serif;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: normal;

		&:first-of-type {
			// border-top: solid 1px $table-border;
			// border-left: solid 1px $table-border;
			// background: $table-header;
			// color: white;
			// border-color: $table-header-border;
			/* background-color: #ffffff; */
			/* padding: 8px; */
			font-size: 11px;
			line-height: normal;
			/* position: absolute; */
			/* top: 0px; */
			white-space: pre-line;
			/* width: auto; */
			color: #737373;

			&:hover {
				background: white;
				transition: 500ms;
				.cell-link {
					text-decoration: underline;
				}
			}
		}
		// &.row:nth-child(odd) .flex-row {
		// 	background: $row-bg;
		// }
		&:hover {
			background: #f5f5f5;
			transition: 500ms;
			.cell-link {
				text-decoration: underline;
			}
		}

		.short {
			width: calc((50% - 50px) / 11);
		}
		.wide {
			width: calc((200% - 50px) / 11);
		}
	}

	.flex-row {
		width: calc((100% - 50px) / 11);
		// text-align: center;
		padding: 0.5em 0.5em;
		// border-right: solid 1px $table-border;
		border-bottom: solid 1px $table-border;

		display: flex;
		visibility: visible;
		align-items: center;

		&:last-of-type {
			width: 50px;
		}

		&--list {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		> svg {
			min-width: 24px !important;
		}

		&--sortable {
			user-select: none;

			&:hover {
				cursor: pointer;

				.sort-icon {
					visibility: visible;
				}
			}
		}

		// Sorting icon
		.sort-icon {
			visibility: hidden;
			opacity: 0.3;

			&--active {
				opacity: 1;
				visibility: visible;
			}

			&--desc {
				transform: rotate(180deg);
			}
		}
	}

	.flex-cell {
		width: calc(100% / 3); //1px = border right
		text-align: center;
		padding: 0.5em 0.5em;
		// border-right: solid 1px $table-border;
		//flex: 1 1 33.3%;
		&:last-child {
			// border-right: 0;
		}
	}

	&__filters {
		display: none;
		flex-direction: column;
		justify-content: flex-end;
		align-items: end;

		&__label {
			padding-right: 16px;
		}
		button {
			margin-right: 8px;
		}

		button > span {
			font-family: 'Roboto' !important;
		}

		&__date {
			display: flex;
			align-items: baseline;
			margin-bottom: 8px;
		}

		&__generic {
			margin-top: 16px;
			display: flex;
			align-items: baseline;
			flex-wrap: wrap;
		}

		&--open {
			display: flex;
		}
	}

	&__owners-modal {
		.ap-modal-body {
			overflow-y: visible;
			padding: 24px 16px 40px;
		}
	}

	&__wrapper {
		display: flex;
	}

	&__data {
		min-width: 0;
		flex: 3;

		&__title {
			font-weight: 500;
			font-size: 28px;
			line-height: 35px;
			letter-spacing: 0.304348px;
			color: #000000;
			margin-bottom: 16px;
		}

		&__description {
			padding-right: 90px;
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.3px;
			color: #000000;

			.short-text {
				word-break: break-word;
			}

			.readMoreText {
				color: $green1 !important;
				font-weight: bold;
				letter-spacing: 0.8px;
				line-height: 19px;
			}
		}

		&__wrapper {
			margin-top: 16px;
			display: flex;
		}
	}

	&__metric {
		margin-right: 32px;
		min-width: 140px;

		&__value {
			display: flex;
			align-items: center;
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.3px;
			color: #1a1a1a;
		}
	}

	&__chart {
		min-width: 0;
		flex: 2;
	}

	&__title {
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;
		color: $grey5;
		padding-bottom: 24px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		&__link:hover {
			text-decoration: underline;
			cursor: pointer;
			color: $grey8;
		}
	}

	&__name {
		color: $grey8;
	}

	&__h1 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0.3px;
		color: $grey8;
	}

	&__h2 {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		padding-bottom: 8px;
		color: $grey5;
	}

	&__heading-wrapper {
		display: flex;
		align-items: center;
		padding-bottom: 16px;
		justify-content: space-between;

		.button-filter {
			margin-right: 16px;
		}
	}

	&__description-wrapper {
		min-height: 94px;
		cursor: pointer;
		&:hover {
			.watchlist__description__edit-icon {
				visibility: visible;
			}

			.watchlist__description {
				text-decoration: underline;
				text-decoration-color: $grey3;
			}
		}
	}

	&__description {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $grey8;
		position: relative;

		&__edit {
			height: 20px;
			position: absolute;
			left: -26px;
			top: -3px;
		}

		&__edit-icon {
			transition: none;
			visibility: hidden;
		}
	}

	&__line {
		height: 1px;
		background-color: $grey3;
		width: 100%;
		margin-top: 32px;
		margin-bottom: 24px;
	}

	&__info {
		padding-top: 32px;
		display: flex;
	}

	&__status {
		flex: 1;
	}

	&__date {
		flex: 1;

		&__value {
			min-height: 36px;
			display: flex;
			align-items: center;
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.3px;
			color: #1a1a1a;
		}
	}

	&__progress {
		flex: 5;

		&__wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;
		}

		&__legend {
			display: flex;

			.watchlist__h2 {
				margin-right: 8px;
			}

			&__good,
			&__pursuit,
			&__drop {
				width: 13px;
				height: 13px;
				transform: translate(0, 3px);
				margin-right: 8px;
			}

			&__good {
				background-color: $green1;
			}
			&__pursuit {
				background-color: $amber;
				width: 13px;
				height: 13px;
				transform: translate(0, 3px);
				margin-right: 8px;
			}
			&__drop {
				background-color: $grey2;
			}
		}
	}

	&__owner {
		margin-right: 8px;
		margin-bottom: 8px;

		> span:first-child {
			padding-left: 20px;
		}

		&__avatar {
			background-color: #999999;
			border-radius: 50%;
			width: 28px;
			height: 28px;
			position: absolute;
			top: 1px;
			left: 1px;

			> img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.disabled {
		cursor: default;
	}

	.cell-link {
		&:hover {
			cursor: pointer;
			color: $green2;
		}

		&:active {
			color: $green3;
		}
	}

	&__companies {
		padding-bottom: 40px;
		min-height: 500px;

		.ap-simple-table-container {
			height: 100%;
		}

		.ap-simple-table-body-cell {
			padding: 0;
			border: 0;

			max-width: 150px;
		}

		.ap-simple-table-row {
			&:hover {
				> td {
					background-color: $grey1;
				}

				.cell-link {
					text-decoration: underline;
				}
			}
		}

		.cell-wrapper {
			min-height: 41px;
			padding: 12px;
			border-bottom: 1px solid $grey3;

			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.cell-action-wrapper {
			cursor: pointer;
			height: 41px;

			&:hover {
				svg {
					fill: #333333;
				}
			}
		}
	}
}

.add-company,
.edit-watchlist {
	.ap-modal-body {
		overflow-y: visible;
	}
}

.watchlist-report {
	&__note {
		color: $grey8;
		font-size: 13px;
		letter-spacing: 0.8px;
		line-height: 19px;
		font-style: italic;
		padding-top: 8px;
		font-weight: normal;
		margin-bottom: 24px;
	}

	&__heading {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.3px;
		color: #000000;
		margin-top: 24px;
	}

	.ap-modal-content {
		max-width: 1000px;
	}
	.ap-modal-header {
		button {
			align-self: baseline;
		}

		h4 {
			width: 100%;
			display: flex;
			flex-direction: column;
		}
		border: none;
	}
	.ap-modal-body {
		height: 571px;
		padding: 0 16px 0 16px;
	}

	&__filter-label {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 17px;
		letter-spacing: 0.3px;
		text-transform: uppercase;
		color: #000000;
		padding: 0 15px;
	}

	&__filters {
		display: flex;
	}

	&__select-all {
		display: flex;
		align-items: center;
		padding-left: 8px;
		flex: 1;

		span:last-child {
			font-family: Roboto;
			font-size: 16px;
			line-height: 24px;
			padding-left: 15px;
			display: flex;
			align-items: center;
			color: $grey8;
		}
	}

	&__select-type {
		padding-left: 16px;
		padding-right: 16px;
		display: flex;
		align-items: baseline;
	}

	&__date {
		display: flex;
		align-items: baseline;

		.DayPickerInput {
			width: 100%;
			input {
				border: 1px solid $grey4;
				border-radius: 1px;
				outline: none;
				position: relative;
				width: 100%;
				padding: 8px;
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 0.3px;
				color: #1a1a1a;

				&:hover,
				&:focus {
					border-color: $green2;
					outline: none;
				}

				&:active {
					border-color: $green3;
				}
			}
		}

		.DayPickerInput-Overlay {
			z-index: 11;
		}
		.DayPicker-Month {
			margin: 32px;
		}

		.DayPicker-Caption {
			padding: 0;
		}
		.DayPicker-Caption > div {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.3px;
			color: #737373;
		}

		.DayPicker-Weekday {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 11px;
			line-height: 13px;
			letter-spacing: 0.792793px;
			text-transform: uppercase;
			color: $grey5;
			padding: 0;
		}

		.DayPicker-Day {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.3px;
			color: $grey7;
		}

		.DayPicker:not(.DayPicker--interactionDisabled)
			.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
			background-color: #5cb247;
			color: $white;
		}

		.DayPicker-Day--start,
		.DayPicker-Day--end {
			background-color: #5cb247 !important;
			color: $white !important;
		}

		.DayPicker-Months {
			flex-wrap: nowrap;
		}

		.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
			position: relative;
			background-color: #eff8ed;
			color: #333333;
		}

		.DayPicker-Day {
			border-radius: 0 !important;
		}
	}
}
