@import '../../styles/variables.scss';

.company-logo {
	height: 150px;
	width: 150px;
	max-width: 100%;
	max-height: 100%;
	// border: 1px solid $grey2;
	background-color: $white;
	margin-right: 24px;
	flex-shrink: 0;
	object-fit: contain;

	&.sml {
		height: 80px;
		width: 80px;
	}

	> img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}
