@import '../../styles/variables.scss';

.watchlists {
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;

	.ap-button-secondary {
		margin-bottom: 0 !important;
	}

	&__title {
		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
	}

	&__list {
		.ap-simple-table-body-cell {
			padding: 2px 8px !important;
		}
	}

	&__label {
		color: $grey5;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
		text-transform: uppercase;
	}

	&__value {
		margin-top: 8px;
		color: $grey7;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
	}

	&__wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		row-gap: 16px;

		> div:last-child {
			grid-column-start: 2;
		}
	}
}

.company-watchlist-modal {
	.ap-modal-body {
		overflow-y: visible;
	}
}
