@import '../../styles/variables.scss';

.news {
	background-color: $white;
	box-shadow: $box-shadow1;
	padding: 24px;
	position: relative;

	&__title {
		display: flex;
		justify-content: space-between;

		color: $grey5;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
		text-transform: uppercase;
		padding-bottom: 24px;
	}

	&__info {
		font-size: 11px;
		font-weight: normal;
		text-transform: none;
	}

	&__categories {
		display: flex;
		flex-wrap: wrap;
	}

	&__category {
		margin: 0 8px 8px 0;
	}

	&__list {
		display: flex;
		flex-direction: column;
		max-height: 650px;
		overflow-y: auto;
	}

	&__item {
		padding-top: 24px;
		margin-right: 16px;
		display: flex;
		flex-direction: column;

		&:first-child {
			padding-top: 16px;
		}

		&__date {
			color: $grey5;
			font-size: 11px;
			letter-spacing: 0;
			line-height: 13px;
			padding-bottom: 8px;
		}

		&__data {
			display: flex;
		}

		img {
			width: auto;
			height: 80px;
			margin-right: 24px;
		}
	}

	&__link {
		color: $grey7;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: 24px;
		text-decoration: none;

		&:hover {
			color: $green1;
			text-decoration: underline;
		}
	}

	&__load-more {
		display: flex;
		justify-content: center;
		background-color: $white;

		button {
			margin-bottom: 0 !important;
			margin-top: 16px;
		}
	}

	&__image-placeholder {
		width: 80px;
		height: 80px;
		margin-right: 24px;
		flex-shrink: 0;
		background-color: $grey1;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
	}

	&__source {
		color: $grey5;
		font-size: 13px;
		letter-spacing: 0.8px;
		line-height: 19px;

		padding-bottom: 8px;
	}
}
