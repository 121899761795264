@import './variables.scss';
@import './grid-config.scss';
@import './ap-table.scss';
@import './typography.scss';
@import './mixins.scss';
@import './helpers.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;600;700&display=swap');

* {
	box-sizing: border-box;
}

.wrapper {
	padding: 0 40px;
	width: 100%;
	height: 100%;

	&--search {
		background: linear-gradient(90deg, $white 50%, $grey1 50%);
	}
}

.app {
	display: block;
	margin-top: $header-height;
	@include calculated-min-height;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.hidden-charts {
	position: absolute;
	left: -99999px;

	.deck-chart {
		display: inline-block;
	}
}

.control-modal {
	.ap-modal-lightbox {
		user-select: none;
		pointer-events: none;
	}
}

.ap-ip-logo {
	cursor: pointer;
}

.separator {
	height: 24px;
	width: 1px;
	background-color: $green1;
	margin: 0 12px;
}

.header-text {
	color: $grey1;
	letter-spacing: 1.48px;
	line-height: 20px;
	font-size: 24px;
}

.secondary-nav {
	display: flex;
	align-items: center;
	height: 48px;
	background-color: $white;
	border-bottom: 1px solid $grey3;
	position: fixed;
	top: $header-height;
	right: 0;
	left: 0;
	z-index: 111;

	.secondary-nav-container {
		display: flex;
		justify-content: space-between;
		height: 100%;

		.ap-button-tab {
			// margin-top: 4px;
		}
	}

	.apply-button-container {
		display: flex;
		align-items: center;
		height: 100%;
	}
}

.contact-us {
	color: $white;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.margin {
	&-top-8 {
		margin-top: 8px;
	}

	&-top-16 {
		margin-top: 16px;
	}
}

.remove-scroll {
	overflow: hidden !important;
}

.container {
	margin-top: 144px;
	// height: calc(100vh - 112px);
}

.button-wrapper {
	display: flex;
	align-items: center;
}

.ap-simple-table-container {
	overflow-y: auto !important;
}

// Rewrite filters max height
// script + div:not(.analysis-modal, .peers-modal) {
// 	max-height: 300px !important;
// 	z-index: 2;
// }

// TODO: check modals
// script + div:first {
// 	max-height: 300px !important;
// 	z-index: 2;
// }

.ap-snack-container {
	z-index: 1000 !important;
}

.ap-modal-wrapper {
	transform: none !important;
}

// ApSsmpleDropdown max height
div[role='tooltip'] {
	max-height: 300px;
}

.text-align-right {
	text-align: right !important;
}

.text-align-left {
	text-align: left !important;
}

.position-relative {
	position: relative;
}

.numeric {
	font-family: 'Roboto Mono', monospace;
}

.numericCell {
	display: flex !important;
	justify-content: flex-end;
	font-family: 'Roboto Mono', monospace;
}
