@import '../../styles/variables.scss';

.filter {
	min-width: 278px;

	.filter-group {
		border-top: 1px solid $grey3;
		&:first-child {
			border: none;
		}

		.filter-group-title {
			display: flex;
			justify-content: space-between;
			padding: 12px 0;
			align-items: center;
			cursor: pointer;

			&__name {
				color: $grey7;
				text-transform: uppercase;
				font-size: 13px;
				font-weight: bold;
				letter-spacing: 0.8px;
				line-height: 19px;
			}
		}
	}

	.sub-filters {
		margin-left: 40px;
	}

	.sub-filter {
		display: flex;
		padding: 0 14px 14px;
	}

	.filter-item {
		display: flex;
		padding: 0 14px 14px;

		> div {
			&:first-child {
				padding-right: 8px;
				cursor: pointer;
			}

			display: flex;
			align-items: center;
		}

		// Overwrite label left pading
		span:nth-child(3) {
			padding-left: 12px;
		}

		&:last-of-type {
			padding: 0 14px 22px;
		}
	}
}
