@import '../../styles/variables.scss';

@mixin label() {
	text-transform: uppercase;
	color: $grey5;
	font-size: 11px;
	line-height: 13px;
	padding-bottom: 4px;
}

.company-card {
	box-shadow: $box-shadow1;
	min-width: 827px;
	padding: 24px;
	border: 1px solid transparent;
	margin-bottom: 16px;
	cursor: pointer;
	background-color: $white;

	&:hover {
		border: 1px solid $green1;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&--selected {
		border: 1px solid $green1;
	}

	&__separator {
		margin: 16px 0 16px;
		height: 1px;
		background-color: $grey3;
		width: 100%;
	}

	&__taxonomy {
		&__label {
			@include label;
		}

		&__data {
			color: $grey8;
			font-size: 13px;
			letter-spacing: 0.8px;
			line-height: 19px;
		}
	}

	.header {
		display: flex;
		&__logo {
			width: 75px;
			height: 75px;
			border: 1px solid $grey2;
		}

		&__logo-url {
			position: relative;
		}

		&__info {
			display: flex;
			flex-direction: column;

			&__name {
				color: $grey7;
				font-size: 19px;
				font-weight: bold;
				line-height: 27px;
				padding-bottom: 8px;
			}

			&__label {
				@include label;
			}

			&__additional-names {
				color: $grey8;
				font-size: 13px;
				letter-spacing: 0.8px;
				line-height: 19px;
			}
		}

		&__actions {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			&__alert {
				flex: 1;
				display: flex;
				align-items: flex-start;
			}

			&__links {
				flex: 1;
				display: flex;
				align-items: flex-end;

				button:nth-child(1) {
					padding-right: 24px;
				}
			}
		}
	}

	.eng-table {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		border: 1px solid $grey3;

		&__header {
			display: flex;
			background-color: $grey1;

			&__cell {
				flex: 1;
				display: flex;
				justify-content: flex-end;
				text-transform: uppercase;
				color: $grey5;
				font-size: 11px;
				line-height: 13px;
				padding: 8px 16px;

				&--title {
					flex: 3;
					justify-content: flex-start;
				}
			}
		}

		&__row {
			border-bottom: 1px solid $grey3;
			display: flex;

			&:last-child {
				border: none;
			}

			&__cell {
				flex: 1;
				display: flex;
				justify-content: flex-end;
				padding: 8px 16px;
				color: $grey7;
				font-size: 13px;
				letter-spacing: 0.8px;
				line-height: 19px;

				&--title {
					flex: 3;
					justify-content: flex-start;
				}
			}
		}
	}
}
