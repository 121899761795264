@import '../../styles/variables.scss';

.tags-search-wrapper {
	display: flex;
}

.tags-search {
	display: flex;
	width: 100%;
	align-items: center;
	margin-bottom: 8px;

	&__new-tag {
		// align-items: flex-end;
		// margin-top: 19px;
		flex: 1;
	}

	&__wrapper {
		position: relative;
		flex: 4;
	}

	.results {
		&__spinner {
			padding: 16px;
		}

		display: flex;
		position: absolute;
		background-color: $white;
		right: 0;
		left: 0;

		border: 1px solid $grey3;
		font-weight: 300;
		font-size: 13px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 2;
		max-height: 200px;
		overflow: auto;

		ul {
			width: 100%;
			margin: 0px;
			padding: 0px;
			list-style-type: none;
		}

		li {
			&:hover {
				cursor: pointer;
				background-color: $grey3;
			}

			cursor: pointer;
			font-weight: 400;
			padding: 8px;
			display: list-item;
		}
	}
}
