@import '../../styles/variables.scss';

.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;

	.ap-loader-default {
		z-index: 101;
	}
}

.company-page {
	&__header {
		background-color: $white;

		&__wrapper {
			display: flex;
		}
	}

	&__navigation {
		padding: 0;
		background-color: $white;
		z-index: 2;
		position: relative;

		&__back-button {
			position: absolute;
			z-index: 1;
			display: none;
		}

		&--sticky {
			position: fixed;

			.company-page__navigation__back-button {
				display: block;
			}

			+ .company-page__content {
				padding-top: 72px;
			}

			position: fixed;
			top: $header-height;
			right: 0;
			left: 0;
		}
	}

	&__content {
		padding-top: 24px;
	}
}

.company-desc {
	flex: 4;
	padding: 16px 0 0 0;
	color: $grey7;

	&__wrapper {
		display: flex;
		padding: 16px 0 0 0;
	}

	&__name {
		font-size: 33px;
		font-weight: 500;
		letter-spacing: 0.3px;
		line-height: 41px;
	}

	&__about {
		font-size: 14px;
		letter-spacing: 0.8px;
		line-height: 22px;
	}

	.readMoreText {
		color: $green1 !important;
		font-weight: bold;
		letter-spacing: 0.8px;
		line-height: 19px;
	}
}

.company-actions {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	padding: 16px;
	align-items: center;
	justify-content: flex-start;
}

.peers-modal {
	.peers-search {
		margin-left: 16px;
		margin-right: 8px;
	}

	.ap-modal-content {
		max-width: 825px;
	}

	h4 {
		width: 100%;
	}

	&__footer,
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.ap-button-main {
			margin-left: 16px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		max-height: 500px;
		flex-wrap: wrap;
		width: fit-content;

		button {
			justify-content: start;
			margin: 0 16px 8px 0;
		}
	}

	&__item {
		&--selected {
			button {
				background-color: $lightGreen1;
			}
		}
	}
}

.watchlist-modal {
	.ap-modal-body {
		overflow-y: auto;
		padding: 24px 16px 40px;
	}
}
