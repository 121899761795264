@import '../../styles/variables.scss';

.content {
	padding: 24px 0 0 16px;
	background-color: $grey1;
	min-height: calc(100vh - 64px);
	min-width: 827px;

	&__note {
		color: $grey8;
		font-size: 13px;
		letter-spacing: 0.8px;
		line-height: 19px;
		font-style: italic;
		padding-top: 8px;
	}

	&__header {
		color: $grey7;
		font-size: 23px;
		font-weight: 500;
		letter-spacing: 0.3px;
		line-height: 31px;
		padding-bottom: 24px;
	}

	&__search-results.end-reached {
		padding-bottom: 16px;
	}

	&__load-more {
		display: flex;
		justify-content: center;
		padding: 16px 0;
		height: 90px;
		align-items: center;
	}
}
