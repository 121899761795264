@import '../../styles/variables.scss';

.table-panel {
	position: relative;
	width: 100%;

	&__info {
		color: $grey5;
		font-size: 13px;
		letter-spacing: 0;
		line-height: 16px;
		margin-bottom: 16px;
	}

	&__hr {
		background-color: $grey2;
		width: 100%;
		display: block;
		padding: 0;
		margin: 0 0 $space-md;
		height: 1px;
	}

	&__grid {
		margin: 0;
		width: 100%;
		height: calc(100vh - 250px);
	}

	.manage-columns-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 8px;
	}
}

.cell-link {
	text-decoration: none;
	color: $grey7;

	&:hover {
		text-decoration: underline;
	}
}

.cell-wishlist-link {
	background-color: $grey2;
	padding: 0 10px;
	border-radius: 15px;
	display: inline-block;
	margin-left: 5px;
	text-decoration: none;
	color: $grey7;

	&:hover {
		text-decoration: underline;
		background-color: $white;
	}
}

.ag-row .ag-cell {
	display: flex;
	align-items: center;

	&.ag-right-aligned-cell {
		justify-content: center;
	}
}
