@import '../../../styles/variables.scss';

.summary-section {
	&__container {
		min-height: 200px;
		display: flex;
		> div {
			flex: 1;
		}
	}

	&__chart {
		margin-right: 16px;
	}
	&__quarterly {
		margin-left: 16px;
	}

	&__row {
		display: flex;
		> div {
			flex: 1;

			&:first-child {
				margin-right: 32px;
			}
		}

		&:first-child {
			margin-bottom: 32px;
		}
	}

	&__description {
		margin: 24px 0 32px;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: $black;
	}

	&__empty {
		font-size: 11px;
		line-height: 13px;
		text-align: center;
		letter-spacing: 0.8px;
		text-transform: uppercase;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		left: 0;
	}
}
