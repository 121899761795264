@import '../../styles/variables.scss';

.search-container {
	display: flex;
	min-height: calc(100vh - 64px);

	&__sidebar {
		flex-basis: 324px;
	}

	&__content {
		flex: 1;
	}
}
