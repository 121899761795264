@import '../../../styles/variables.scss';

.pl-widget {
	min-width: 200px;
	max-height: 352px;
	background: $white;
	// box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	padding: 16px 20px;

	.chart-blue {
		color: #78b0be;
	}

	&__legend {
		i {
			color: #78b0be;
			// color: #737373;
		}
	}

	&__title {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;

		letter-spacing: 0.8px;
		text-transform: uppercase;

		color: $grey8;

		padding-bottom: 16px;
	}

	&__comments {
		padding-top: 16px;

		img {
			padding-right: 8px;
		}

		display: flex;
		align-items: center;
	}

	&__data {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;

		color: $grey8;
	}

	&__separator {
		height: 1px;
		width: 100%;
		background-color: $grey3;
		margin-bottom: 16px;
	}

	&__change {
		display: flex;
		justify-content: center;
		padding: 16px 0;

		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 27px;

		color: $grey8;

		display: flex;
		align-items: center;
		transform: translateX(-8px);
	}

	&__change-icon {
		transform: rotate(270deg);

		&--negative {
			transform: rotate(90deg);
			fill: $red1;
		}
	}
}
