@import './variables.scss';

body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: $body-min-width;
	background-color: $grey2;
	font-family: $font-family;
	* {
		box-sizing: border-box;
	}
}

@font-face {
	font-family: $font-family;
	src: url('/fonts/Roboto-Bold.woff') format('woff');
	src: url('/fonts/Roboto-Light.woff') format('woff');
	src: url('/fonts/Roboto-Medium.woff') format('woff');
	src: url('/fonts/Roboto-Regular.woff') format('woff');
}

$h1-font-size: 83px;
$h1-line-height: 88px;

$h2-font-size: 40px;
$h2-line-height: 44px;

$h3-font-size: 33px;
$h3-line-height: 41px;

$h4-font-size: 28px;
$h4-line-height: 35px;

$h5-font-size: 23px;
$h5-line-height: 31px;

$h6-font-size: 19px;
$h6-line-height: 27px;

$p-font-size: 16px;
$p-line-height: 24px;

h1,
h2,
h3,
h4,
h5,
h6,
h6,
p {
	font-family: $font-family;
	margin: 0;
}

h1 {
	font-size: $h2-font-size;
	line-height: $h2-line-height;
	font-weight: $font-weight-medium;
	letter-spacing: 0.7px;
	@media (min-width: $md) {
		font-size: $h1-font-size;
		line-height: $h1-line-height;
	}
}

h2 {
	font-size: $h4-font-size;
	line-height: $h4-line-height;
	font-weight: $font-weight-bold;
	letter-spacing: 0.3px;
	@media (min-width: $md) {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}
}

h3 {
	font-size: $h5-font-size;
	line-height: $h5-line-height;
	font-weight: $font-weight-medium;
	letter-spacing: 0.3px;
	@media (min-width: $md) {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}
}

h4 {
	font-size: $h6-font-size;
	line-height: $h6-line-height;
	font-weight: $font-weight-medium;
	letter-spacing: 0.3px;
	@media (min-width: $md) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}
}

h5 {
	font-size: $p-font-size;
	line-height: $p-line-height;
	font-weight: $font-weight-medium;
	letter-spacing: 0.3px;
	@media (min-width: $md) {
		font-size: $h5-font-size;
		line-height: $h5-line-height;
	}
}

h6 {
	font-size: $p-font-size;
	line-height: $p-line-height;
	letter-spacing: 0px;
	font-weight: $font-weight-regular;
	@media (min-width: $md) {
		font-size: $h6-font-size;
		line-height: $h6-line-height;
	}
}

p {
	font-size: $p-font-size;
	line-height: $p-line-height;
	font-weight: $font-weight-regular;
	letter-spacing: 0.3px;
}
